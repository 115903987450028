import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { logout } from 'store/actions/app'
import queryString from 'query-string'
import moment from 'moment'

import { callApi } from 'utils/api'
import { getStateValue } from 'store/selectors'
import documentItemActions from 'store/actions/documents_items'
import studentNotificationActions from 'store/actions/students_notifications'
import studentActions from 'store/actions/students'

import './Header.scss'

import {
	Message,
	Button,
	Menu,
	Icon,
	Popup,
	Image,
	Grid,
	Label,
	Header,
	Segment,
} from 'semantic-ui-react'

const getAvatar = (user, client) => {
	if (user?.photo && user?.photo.length > 0) {
		return user.photo
	} else {
		let name = 'Guest'
		if (user) {
			name = user?.entity?.name ?? user?.username
		}
		if (client) {
			name = client?.code
		}
		return `https://ui-avatars.com/api/?name=${name}`
	}
}

const HeaderComponent = (props) => {
	const {
		user,
		client,
		rest,
		logout,
		history,
		expirations,
		employees,
		setExpand,
		openModal,
		setOpenModal,
		mobileOnly,
		previousExpand,
		setPreviousExpand,
	} = props

	const today = new Date(
		new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000
	)
		.toISOString()
		.substring(0, 10)

	const [modalVisible, setModalVisible] = useState(false)
	// const [employes, setEmployees] = useState([])
	// const [canCheckLicense, setCanCheckLicense] = useState(false)
	const [notificationsCount, setNotificationsCount] = useState(0)

	const [initialDate, setInitialDate] = useState(today + 'T00:00')
	const [finalDate, setFinalDate] = useState(today + 'T23:59')
	// const [bindings, setBindings] = useState([])
	// const [arrayStudentsList, setArrayStudentsList] = useState([])

	useEffect(() => {
		componentDidMount()
	}, [])

	const componentDidMount = async () => {
		let notifications = await props.getStudentsNotifications({})
		let total_notifications =
			notifications.notifications +
			notifications.students?.suspended +
			notifications.students?.temporal_discharged +
			notifications.students?.permanent_discharged +
			notifications.students?.graduated
		setNotificationsCount({
			...notifications,
			total_notifications: total_notifications,
		})
	}

	let userName = rest.user?.username

	return (
		<Menu
			className='NavBar__Header'
			inverted
			borderless
			size='tiny'
			attached='top'
			fixed='top'
		>
			<Menu.Item className='NavBar__Item'>
				<Icon
					className='NavBar__Outline'
					name='list'
					size='large'
					style={{ cursor: 'pointer' }}
					onClick={() => {
						setExpand((previousExpandedState) => {
							setPreviousExpand(previousExpandedState)
							if (!mobileOnly)
								return previousExpandedState == 1
									? previousExpand == null || previousExpand == 2
										? 0
										: 2
									: 1
							return !previousExpandedState ? 1 : 0
						})
					}}
				/>
			</Menu.Item>
			<Menu className='SubMenu__Header' size='mini'>
				<Menu.Item
					onClick={() => {
						history.push('/')
					}}
				>
					<Header className='NavBar__Item'>
						<h4 className='NavBar__Outline'>CBTIS 140</h4>
						<Header.Subheader className='NavBar__Outline'>
							V 0.12.16
						</Header.Subheader>
					</Header>
				</Menu.Item>
				<Menu.Item fitted position='right'>
					<Icon
						className='Circle_Icon NavBar__Item'
						name='question'
						circular
						onClick={() => {
							setOpenModal(!openModal)
						}}
					/>
				</Menu.Item>
			</Menu>
			<Menu.Menu position={mobileOnly ? null : 'right'} borderless size='tiny'>
				<Menu.Item position='right' fitted className='NavBar__Item'>
					<Popup
						pinned
						trigger={
							<Menu.Item style={{ marginRight: '1vw', padding: '0 0.8em' }}>
								<Icon className='NavBar__Outline' size='big' name='bell' fitted>
									{notificationsCount.total_notifications > 0 && (
										<Label
											color='red'
											floating
											circular
											style={{
												fontFamily:
													"Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
												fontSize: '0.45em',
											}}
										>
											{notificationsCount.total_notifications}
										</Label>
									)}
								</Icon>
							</Menu.Item>
						}
						content={
							<div>
								{notificationsCount?.notifications === 0 &&
									notificationsCount.students?.suspended === 0 &&
									notificationsCount.students?.temporal_discharged === 0 &&
									notificationsCount.students?.permanent_discharged === 0 &&
									notificationsCount.students?.graduated === 0 && (
										<Message
											size='tiny'
											warning
											icon='info'
											header={`No hay notificaciones activas`}
										/>
									)}

								{notificationsCount?.notifications > 0 && (
									<Message
										size='tiny'
										onClick={() => {
											history.replace({
												pathname: '/students_notifications',
												search: queryString.stringify({
													tabId: 0,
												}),
											})
										}}
										warning
										icon='warning'
										header={
											notificationsCount.notifications > 1
												? `${notificationsCount.notifications} Notificaciones activas`
												: `${notificationsCount.notifications} Notificación activa`
										}
										content='Click aquí para ver detalles'
										style={{ cursor: 'pointer' }}
									/>
								)}
								{notificationsCount.students?.suspended > 0 && (
									<Message
										size='tiny'
										onClick={() => {
											history.replace({
												pathname: '/students_report',
												search: queryString.stringify({
													status: 'suspended',
												}),
											})
										}}
										negative
										icon='warning'
										header={
											notificationsCount.students.suspended > 1
												? `${notificationsCount.students.suspended} Alumnos suspendidos`
												: `${notificationsCount.students.suspended} Alumno suspendido`
										}
										content='Click aquí para ver detalles'
										style={{ cursor: 'pointer' }}
									/>
								)}
								{notificationsCount.students?.temporal_discharged > 0 && (
									<Message
										size='tiny'
										onClick={() => {
											history.replace({
												pathname: '/students_report',
												search: queryString.stringify({
													status: 'temporal_discharged',
												}),
											})
										}}
										warning
										icon='warning'
										header={
											notificationsCount.students.temporal_discharged > 1
												? `${notificationsCount.students.temporal_discharged} Alumnos con baja temporal.`
												: `${notificationsCount.students.temporal_discharged} Alumno con baja temporal.`
										}
										content='Click aquí para ver detalles'
										style={{ cursor: 'pointer' }}
									/>
								)}
								{notificationsCount.students?.permanent_discharged > 0 && (
									<Message
										size='tiny'
										onClick={() => {
											history.replace({
												pathname: '/students_report',
												search: queryString.stringify({
													status: 'permanent_discharged',
												}),
											})
										}}
										warning
										icon='warning'
										header={
											notificationsCount.students.permanent_discharged > 1
												? `${notificationsCount.students.permanent_discharged} Alumnos con baja permanente.`
												: `${notificationsCount.students.permanent_discharged} Alumno con baja permanente`
										}
										content='Click aquí para ver detalles'
										style={{ cursor: 'pointer' }}
									/>
								)}
								{notificationsCount.students?.graduated > 0 && (
									<Message
										size='tiny'
										onClick={() => {
											history.replace({
												pathname: '/students_report',
												search: queryString.stringify({
													status: 'graduated',
												}),
											})
										}}
										warning
										icon='warning'
										header={
											notificationsCount.students.graduated > 1
												? `${notificationsCount.students.graduated} Alumnos graduados.`
												: `${notificationsCount.students.graduated} Alumno graduado`
										}
										content='Click aquí para ver detalles'
										style={{ cursor: 'pointer' }}
									/>
								)}
							</div>
						}
						position='bottom right'
						on='click'
					/>
				</Menu.Item>
				<Menu.Item fitted position='right' className='NavBar__Item'>
					<Popup
						onOpen={() => setModalVisible(true)}
						onClose={() => setModalVisible(false)}
						pinned
						on='click'
						trigger={
							<Menu.Item className='NavBar__Item'>
								<Image src={getAvatar(user, client)} avatar />
							</Menu.Item>
						}
						content={
							<Segment className='NavBar__Outline'>
								<Grid
									divided='vertically'
									columns='equal'
									className='Header_PopUp'
									style={{ display: 'inline-block', padding: 0 }}
								>
									<Grid.Row columns={1}>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<Image
												className='Header_PopUp_Image'
												src={getAvatar(user, client)}
												avatar
											/>
										</Grid.Column>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<h4 className='NavBar__InvertedOutline'>
												{userName.toUpperCase()}
											</h4>
										</Grid.Column>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<Button
												className='Header_PopUp_Button'
												basic
												icon
												labelPosition='right'
												onClick={() => {
													logout()
													props.history.push('/login')
												}}
											>
												<Icon name='sign out' />
												Cerrar sesión
											</Button>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Segment>
						}
						position='bottom right'
					/>
				</Menu.Item>
			</Menu.Menu>
		</Menu>
	)
}

const mapDispatchToProps = (dispatch) => ({
	loadExpirationReport: (opt) =>
		dispatch(documentItemActions.loadExpirationReport(opt)),
	logout: () => dispatch(logout()),
	loadStudents: (options) => dispatch(studentActions.load(options)),
	loadNotifications: (options) =>
		dispatch(studentNotificationActions.load(options)),
	loadBindings: (options) =>
		dispatch(studentActions.loadStudentsBindings(options)),
	getStudentsNotifications: (options) =>
		dispatch(studentActions.getStudentsNotifications(options)),
})

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.app.user,
	isClientAuthenticated: !!state.app.client,
	user: state.app.user,
	client: state.app.client,
	employees: state.employees,
	expirations: state.document_items?.expiration_report ?? [],
	students_notifications: getStateValue(state.student_notifications.entities),
	students: getStateValue(state.students.entities),
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)
