const initialState = {}
export default (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case 'LOGIN': {
			return {
				...state,
				user: { ...payload },
			}
		}
		case 'LOGOUT': {
			return {
				...state,
				user: null,
			}
		}

		case 'LOGIN_WIALON': {
			return {
				...state,
				login_wialon: { ...payload },
			}
		}

		case 'LOGIN_CLIENT': {
			return {
				...state,
				client: { ...payload.client },
			}
		}

		case 'CLIENT_LOGOUT': {
			return {
				...state,
				client: null,
			}
		}

		case 'LOGIN_PASSENGER': {
			return {
				...state,
				passenger: { ...payload.passenger },
			}
		}

		case 'PASSENGER_LOGOUT': {
			return {
				...state,
				passenger: null,
			}
		}

		case 'LOGIN_DRIVER': {
			return {
				...state,
				driver: { ...payload.driver },
			}
		}
		case 'DRIVER_LOGOUT': {
			return {
				...state,
				driver: null,
			}
		}

		case 'LOGIN_STUDENT': {
			return {
				...state,
				student: { ...payload.student },
			}
		}
		case 'STUDENT_LOGOUT': {
			return {
				...state,
				student: null,
			}
		}
		case 'IMPORT': {
			return {
				...state,
			}
		}
		default:
			return state
	}
}
