import { useState, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { Route, Redirect } from 'react-router-dom'

import { Header, Segment, Grid, Modal, Divider, List } from 'semantic-ui-react'
import { connect } from 'react-redux'
import MenuHeader from 'components/Header/StudentHeader'
import StudentSidebarMenu from '../Sidebar/StudentSidebarMenu'

const PrivateStudentRoute = (
	{ isAuthenticated = true, component: Component, ...rest },
	props
) => {
	const [expand, setExpand] = useState(0)
	const [openModal, setOpenModal] = useState(false)
	const [mobileOnly, setMobileOnly] = useState(false)
	const [previousExpand, setPreviousExpand] = useState(null)
	return (
		<>
			<Route
				{...rest}
				render={(props) =>
					isAuthenticated ? (
						<Fragment>
							<MenuHeader
								rest={rest}
								logout={rest.logout}
								history={props.history}
								expand={expand}
								setExpand={setExpand}
								previousExpand={previousExpand}
								setPreviousExpand={setPreviousExpand}
								openModal={openModal}
								setOpenModal={setOpenModal}
								mobileOnly={mobileOnly}
								setMobileOnly={setMobileOnly}
							/>
							<div
								className='main-content'
								style={
									expand == null || expand == 2
										? { marginLeft: '250px' }
										: expand == 1
										? { marginLeft: '55px' }
										: null
								}
							>
								{ReactDOM.createPortal(
									<StudentSidebarMenu
										history={props.history}
										location={props.location}
										client={props.client}
										rest={rest}
										expand={expand}
										setExpand={setExpand}
										previousExpand={previousExpand}
										setPreviousExpand={setPreviousExpand}
										openModal={openModal}
										setOpenModal={setOpenModal}
										mobileOnly={mobileOnly}
										setMobileOnly={setMobileOnly}
									/>,
									document.getElementById('root')
								)}
								<Component {...props} />
							</div>
						</Fragment>
					) : (
						<Redirect to='/login_student' />
					)
				}
			/>
			<Modal
				closeIcon
				basic
				centered={true}
				onClose={() => setOpenModal(false)}
				onOpen={() => setOpenModal(true)}
				open={openModal}
				size='large'
			>
				<Modal.Content content>
					<Segment inverted style={{ height: '100%' }}>
						<Grid stackable divided inverted>
							<Grid.Row columns={2}>
								<Grid.Column
									width={6}
									verticalAlign='middle'
									textAlign='center'
								>
									<Header inverted content='CBTIS NO. 140 © 2022' />
								</Grid.Column>
								<Grid.Column width={8}>
									<Header inverted content='Contacto' />
									<Divider></Divider>
									<Header
										size='small'
										inverted
										content='¿Necesitas información de nuestro plantel?'
									/>
									<List divided inverted>
										<List.Item
											header='Ingresa al Portal: '
											content='https://cbtis140.edu.mx/'
											style={{ cursor: 'pointer' }}
											onClick={() => {
												window.open('https://cbtis140.edu.mx/', '_blank')
											}}
										/>
										<List.Item
											header='Administración'
											content='(686) 686 558 2000'
										/>
										<List.Item
											header='Correo Electronico'
											content='cbtis140.dir@dgeti.sems.gob.mx'
										/>
									</List>
									<List inverted>
										<List.Item
											header='Dirección:'
											content='Mexicali, B.C. C.P. 21300'
										/>
										<List.Item content='Fracc. Villanova' />
										<List.Item content='Ave. Francisco Villa 1452' />
									</List>
									<List divided inverted>
										<List.Item
											header='Desarrollado por GPS Geologistic: '
											content='info@geologistic.com.mx'
										/>
									</List>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Segment>
				</Modal.Content>
			</Modal>
		</>
	)
}

const mapDispatchToProps = (dispatch) => ({})

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.app.student,
	client: state.app.student,
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateStudentRoute)
